import * as React from 'react';

import Dog1 from '../images/team/1.gif';
import Dog2 from '../images/team/2.gif';
import Dog3 from '../images/team/3.gif';
import Dog4 from '../images/team/4.gif';
import Dog5 from '../images/team/5.gif';
import Dog6 from '../images/team/6.gif';

import './team.scss';

const Team = () => (
  <section className="team" id="school">
    <div className="team__container">
      <h1>Puppy School</h1>
      <p>
        We need to teach you degens some new tricks! Each Chains on Dog NFTs
        will be 100% on-chain, which comes with pros and cons.
      </p>
      <p>
        <ol>
          <li>
            Cardano currently has an on-chain
            {' '}
            <strong>metadata limit of 16kb</strong>
            , which is really smol. For
            example, a SpaceBudz is ~500kb, and a Chilled Kongs is a whopping
            1MB which is why they can’t be on-chain.
          </li>
          <li>
            To get all 12 frames of animation on-chain, each doggo will be
            generated as an
            {' '}
            <strong>animated PNG</strong>
            {' '}
            (APNG) and encoded to
            base64.
          </li>
          <li>
            APNG is supported by a majority of wallets and pool.pm, so you’ll be
            able to view your animated Doggo in all its glory!
          </li>
          <li>
            APNG support on secondary marketplaces is a dog's breakfast. We’re in
            contact with the major players to help improve support.
          </li>
          <li>
            While the world catches up with our progressive future dog owners,
            we’ll provide a
            {' '}
            <strong>backward-compatible GIF</strong>
            {' '}
            doggo via
            this website that you can right-click and download to use as your
            PFP.
          </li>
        </ol>
      </p>
      <div className="team__list">
        <div className="team__member">
          <img src={Dog1} alt="" />
        </div>
        <div className="team__member">
          <img src={Dog2} alt="" />
        </div>
        <div className="team__member">
          <img src={Dog3} alt="" />
        </div>
        <div className="team__member">
          <img src={Dog4} alt="" />
        </div>
        <div className="team__member">
          <img src={Dog5} alt="" />
        </div>
        <div className="team__member">
          <img src={Dog6} alt="" />
        </div>
      </div>
      {/* <a href="/team" className="team__button">View Full Bios</a> */}
    </div>
  </section>
);

export default Team;
