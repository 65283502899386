import * as React from 'react';

import Content from './content';

import strength from '../images/strength.svg';
import agility from '../images/agility.svg';
import constitution from '../images/constitution.svg';
import luck from '../images/luck.svg';
import charisma from '../images/charisma.svg';
import intelligence from '../images/intelligence.svg';

import './stats.scss';

const Airdrops = () => (
  <div className="mission" id="stats">
    <Content>
      <div className="mission__content">
        <div className="mission__description">
          <h1 className="mission__heading">Doggo Stats</h1>
          <h2>Base Stats</h2>
          <p>Six base stats are generated for each doggo, ranging from 80-100.</p>
          <h2>First &amp; Last Name</h2>
          <p>All dogs have been named based on suggestions from our twitter community.</p>
          <h2>8 Character Classes</h2>
          <p>We made up 8 crazy classes to add more flavour to your doggos.</p>
        </div>
        <div className="mission__examples">
          <div className="mission__card">
            <img src={strength} alt="Strength Icon" />
            <div className="mission__card-content">
              <h1 className="mission__gold">Strength</h1>
              <div className="mission__card-heading">How stronk a doggo is. Useful for carrying the big sticks.</div>

            </div>
          </div>
          <div className="mission__card">
            <img src={agility} alt="Agility Icon" />
            <div className="mission__card-content">
              <h1 className="mission__gold">Agility</h1>
              <div className="mission__card-heading">How spritely a doggo is. Useful for chasing those heckin cats.</div>

            </div>
          </div>
          <div className="mission__card">
            <img src={constitution} alt="Constitution Icon" />
            <div className="mission__card-content">
              <h1 className="mission__gold">Constitution</h1>
              <div className="mission__card-heading">How chonky a doggo is. Useful for eating things you know you're not supposed to and still being okay.</div>
            </div>
          </div>
          <div className="mission__card">
            <img src={intelligence} alt="Intelligence Icon" />
            <div className="mission__card-content">
              <h1 className="mission__gold">Intelligence</h1>
              <div className="mission__card-heading">How smort a doggo is. Useful for learning new tricks.</div>
            </div>
          </div>
          <div className="mission__card">
            <img src={luck} alt="Luck Icon" />
            <div className="mission__card-content">
              <h1 className="mission__gold">Wisdom</h1>
              <div className="mission__card-heading">How wise a doggo is. Useful for remembering old tricks and knowing when to use them.</div>
            </div>
          </div>
          <div className="mission__card">
            <img src={charisma} alt="Charisma Icon" />
            <div className="mission__card-content">
              <h1 className="mission__gold">Charisma</h1>
              <div className="mission__card-heading">How frenly a doggo is. Useful for getting extra pats and treats.</div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </div>
);

export default Airdrops;
