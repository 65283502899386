import * as React from 'react';
import './about.scss';
import jpg from '../images/jpg.png';
import dogbow from '../images/doggo-pink-bow.gif';

const About = () => (
  <section className="about">
    <div className="about__container">
      <div className="about__content">
        <h1>Chains on Dogs!</h1>
        <p>
          Chains on dogs were free-to-mint, 100% on-chain animated NFTs where the
          community is building the utility!
        </p>
        <h2>
          1,000 doggos have been adopted. But you can still find some strays on
          {' '}
          JPG.store.
        </h2>
        <a className="about__minting-button" href="https://www.jpg.store/collection/ChainsonDogs" target="_new"><img src={jpg} alt="Season 2 Minting Now" /></a>
      </div>
      <img src={dogbow} className="pixel" alt="Woof woof woof" height="416px" />
    </div>
  </section>
);

export default About;
