import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import About from '../components/about';
import Seo from '../components/seo';
import Header from '../components/header';
import Stats from '../components/stats';
import GameJam from '../components/gamejam';
import FAQ from '../components/faq';
import Team from '../components/team';

const IndexPage = () => (
  <Layout>
    <Seo title="Chains on Dogs" />
    <Header />
    <About />
    <GameJam />
    <Stats />
    <Team />
    <FAQ />
  </Layout>
);

IndexPage.propTypes = {
  location: PropTypes.shape({
    hostname: PropTypes.string.isRequired,
  }).isRequired,
};

export default IndexPage;
