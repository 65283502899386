import * as React from 'react';

import './faq.scss';

const faqs = [
  {
    question: 'How many doggos?',
    content:
      '1000 Dogs with Chains on-chain will be available. 1 per tx, 2 per wallet.',
  },
  {
    question: 'Mint price?',
    content:
      'Free! You will need to send 3ADA and you will receive 1 Chains on Dog NFT as well as a full refund minus the tx fee (which is usually less than 1 ADA)',
  },
  {
    question: 'You said it was free! Why do I have to send 3ADA?',
    content:
      'Because that is how the blockchain works bro. You will receive your NFT and a full refund minus the tx fee.',
  },
  {
    question: 'Wen mint?',
    content: 'Friday July 22, 2022 11pm UTC',
  },
  {
    question: 'Why?',
    content:
      'Purely for fun! Plus we wanted to push on-chain limitations ourselves by providing unique animated pixel art NFTs completely on-chain.',
  },
  {
    question: 'Which wallet to use?',
    content:
      'Any Cardano wallet is fine, Eternl, Nami, Yoroi, Daedalus etc. ⚠ Do not send any funds from exchange wallets ⚠',
  },
  {
    question: 'Royalties',
    content:
      'Chains on Dogs have 10% royalties when sold on secondary marketplaces.',
  },

  {
    question: 'Policy ID',
    content: '13b1e946ae6fd21e77a40de3005716a753d01fd69a70f389d0fb07b3',
  },
];

const Faq = () => (
  <section className="faq" id="faq">
    <div className="faq__content">
      <h1>Doggo De-tails</h1>
      {faqs.map(({ question, content }, index) => {
        const id = `faq-${index}`;
        return (
          <div key={question} className="faq__item">
            <input type="checkbox" id={id} />
            <label htmlFor={id}>
              <h2>{`+ ${question}`}</h2>
            </label>
            <div className="faq__answer">{content}</div>
          </div>
        );
      })}
    </div>
  </section>
);

export default Faq;
