import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import Content from './content';
import fire from '../images/doggo-fire-hydrant.gif';

import './gamejam.scss';

const Card = ({
  color,
  icon,
  image,
  heading,
  children,
  backgroundImage,
  size,
}) => (
  <div
    href="/explore?season=season-1-road-trip"
    style={{
      backgroundImage: backgroundImage && `url(${backgroundImage})`,
      borderColor: color && color,
    }}
    className={classNames('roadmap__item', {
      '-large': size === 'large',
    })}
  >
    {icon && <img className="roadmap__item-icon" src={icon} alt="" />}
    {image && <img className="roadmap__item-image" src={image} alt="" />}
    <div
      className={classNames('roadmap__item-header', {
        '-no-icon': !icon && !image,
      })}
    >
      <div className="roadmap__item-text">
        <h2>{heading}</h2>
        {children}
      </div>
    </div>
  </div>
);

Card.defaultProps = {
  color: 'green',
  icon: null,
  image: null,
  size: 'normal',
  backgroundImage: null,
};

Card.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  image: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.node.isRequired,
  status: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  size: PropTypes.string,
};

const Roadmap = () => (
  <div className="roadmap" id="gamejam">
    <Content>
      <div className="roadmap__content">
        <section className="roadmap__header">
          <div className="roadmap__header-image">
            <img src={fire} alt="Roadmap" height="416px" />
          </div>
          <div>
            <h1>5,000 ADA Prize Pool!</h1>
            <p>We want the community to create the utility for the Doggos!</p>
            <p>
              To help motivate you lazy mongrels, we've buried a 5,000 ADA prize
              (in a wallet) for any budding game developers out there!
              <ul>
                <li>1st Prize: 2,500 ADA</li>
                <li>2nd Prize: 1,500 ADA</li>
                <li>3rd Prize: 1,000 ADA</li>
              </ul>
            </p>
            <p>
              We created Chains on Dogs as a free-to-mint NFT to
              <ol type="a">
                <li>push the boundaries of on-chain NFT limitations and</li>
                <li>encourage and inspire the community to create.</li>
              </ol>
              We believe that NFTs can be much more than flippable trading
              cards, and we believe that greater community involvement in
              building is key.
            </p>
            <p>
              We randomly generated attributes and classes for each pupper to
              provide additional data and inspiration for the community to build
              around. We offer a total prize pool of 5000ADA for any
              budding developers out there to get involved and create fun games
              around Chains on Dogs NFTs.
            </p>
            <p>
              We will also be settling into a new kennel in the
              {' '}
              <a href="https://discord.com/invite/cheekyunts">
                @cheekyunts discord
              </a>
              {' '}
              #game-jam, where you can chat with other puppers about all things
              Chains on Dogs.
            </p>
          </div>
        </section>
        {/* <section>
          <h1>2022 Jan - Jun</h1>
          <div className="roadmap__list">
            <Card status="Done" size="large" image={changingRoom}>
              <h3>
                An exclusive club is about to open for only the most stylish and
                luxurious unts. To be considered “swanky” enough, an unt needs
                to be sporting a limited-edition outfit inspired by the hottest
                designs in the CNFT space.
              </h3>
            </Card>
            <Card
              icon={hypeskulls}
              heading="H.Y.P.E. Skulls x Cheeky Unts"
              status="done"
            >
              <p>
                The first Swanky Unts collaboration will see the emergence of
                HypeUnts!
              </p>
            </Card>
            <Card
              icon={aeoniumsky}
              heading="aeoniumsky x Cheeky Unts"
              status="done"
            >
              <p>
                Round two of Swanky Unts to enter the changeroom will come out
                as aeoniumsky Unts!
              </p>
            </Card>
            <Card
              icon={hopper}
              heading="Happy Hopper x Cheeky Unts"
              status="done"
            >
              <p>You'll be hopping mad if you miss out on a Hopper Unt.</p>
            </Card>
            <Card
              color={orange}
              icon={pubcrawl}
              heading="Pub Crawl - Season Two"
              status="Soon"
            >
              <p>Dropping 28th of May, 4444 new Unts from around the globe!</p>
            </Card>
          </div>
        </section>

        <section className="roadmap__sandbox-bg">
          <h1>2022 Jul - Dec</h1>
          <div className="roadmap__sandbox">
            <Card
              color={orange}
              status="Alpha Live"
              size="large"
              image={skateunt}
            >
              <h3>
                Backed by a team with
                {' '}
                <em>legit</em>
                {' '}
                game development
                experience, we're going to build some ripper experiences for you
                Unts!
              </h3>
              <h3>
                In 2022, we'll be releasing multiple games starting with
                {' '}
                <a href="https://arcade.cheekyunts.net/skateunt/">
                  <strong>Skate Unt</strong>
                </a>
                {' '}
                - play for fun or play to win!
              </h3>
            </Card>
          </div>
        </section> */}
      </div>
    </Content>
  </div>
);

export default Roadmap;
